<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'" 
        :noticeInfo="noticeInfo"></Horizontal>
        <Vertical ref="Vertical" v-else
        :noticeInfo="noticeInfo"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/GameNotice/Horizontal/NoticeDetail.vue'
    import Vertical from '@/components/Mine/GameNotice/Vertical/NoticeDetail.vue'
    
    import avater from '@/assets/menuList/icon_account.svg'
    
    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
              noticeInfo:{}
            }
        },
        created() {
          this.getNoticeInfo()  
        },
        methods:{
           getNoticeInfo() {
               let requestData = {
                   user_id:this.$store.state.userId,
                   token:this.$store.state.token,
                   news_id:this.$route.params.item.news_id,
               }
               this.$api.game.getGameNoticeDetail(requestData).then(res=> {
                   if (res.data.code === 200) {
                       this.noticeInfo = res.data.data
                   }
               })
           }
        }
    }
</script>

<style>
</style>