<template>
    <div class="all-page">
        <TopNav title="公告详情" backPath="GameNoticeIndex"></TopNav>
        <div class="all-page-content-box">
            <div class="title">
                {{noticeInfo.title}}
            </div> 
          <div class="content" v-html="noticeInfo.content"></div>
          
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"

    export default {
        components: {
            TopNav
        },
        props: {
            noticeInfo:Object
        },
        data() {
            return {
                
                
            }
        },
        methods: {
            
        }
    }
</script>

<style scoped>
   .title {
       padding-bottom: 12px;
       font-size: 16px;
   }
</style>
