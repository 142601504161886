<template>
    <div :class="$store.getters.currentPageVersion === 'Horizontal'?'head-title-box-h':'head-title-box'">
        <div :class="$store.getters.currentPageVersion === 'Horizontal'?'icon-box-h':'icon-box'">
            <img @click="goBack" src="@/assets/common/icon_back.svg" :class="$store.getters.currentPageVersion === 'Horizontal'?'back-icon-h':'back-icon'">
        </div>
        <div :class="$store.getters.currentPageVersion === 'Horizontal'?'title-h':'title'">{{title}}</div>
        <div :class="$store.getters.currentPageVersion === 'Horizontal'?'blank-h':'blank'" @click="goPage">{{goPageName}}</div>
    </div>
</template>

<script>
    export default {
        props:{
            title:String,
            backPath:String,
            goPath:String,
            goPageName:String
        },
        methods:{
            goPage() {
                this.$router.push({
                    name: this.$props.goPath,
                    params: {}
                })
            },
            goBack() {
                this.$router.push({
                    name: this.$props.backPath,
                    params: {}
                })
            }
        }
    }
</script>

<style scoped>
    .head-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        height: 40px;
    }
    .head-title-box-h {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12.5px;
        height: 30px;
    }
    .icon-box {
        width: 23%;
    }
    .back-icon {
        width: 24px;
    }
    .title {
        flex: 1;
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        height: 24px;
        line-height: 24px;
        color: #000000;
    }
    .icon-box-h  {
        width: 23%;
    }
    .back-icon-h {
        width: 15px;
    }
    .title-h  {
        flex: 1;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        height: 40px;
        line-height: 40px;
        color: #000000;
    }
    .blank,
    .blank-h {
        width: 23%;
        text-align: end;
    }
    .blank-h {
        font-size: 8.75px;
    }
</style>